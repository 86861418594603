const BASE_PATH = "/payments";
export default (axios: any) => ({
  getAllPayments(params?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/campaigns/all`, { params: { ...params } });
  },
  createPayment(payload: Record<string, any>) {
    return axios.post(`${BASE_PATH}/`, payload);
  },
  updatePayment(payload: Record<string, any>) {
    return axios.patch(`${BASE_PATH}/`, payload);
  },
  deletePayment(influencer_id: number | string) {
    const params = { influencer_id };
    return axios.delete(`${BASE_PATH}/${influencer_id}`);
  },
});