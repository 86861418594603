const BASE_PATH = "/mail";
export default (axios: any) => ({
  sendEmail(payload?: Record<string, any>) {
    return axios.post(`${BASE_PATH}/send`, payload);
  },
  getEmailTemplate(params?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/templates`, { params: { ...params } });
  },
  createEmailTemplate(payload: Record<string, any>) {
    return axios.post(`${BASE_PATH}/templates`, payload);
  },
  deleteEmailTemplate(template_id: number | string) {
    const params = { template_id };
    return axios.delete(`${BASE_PATH}/templates${template_id}`);
  },
  updateEmailTemplate(payload: Record<string, any>) {
    return axios.patch(`${BASE_PATH}/templates`, payload);
  }
});