const BASE_PATH = "/products";
export default (axios: any) => ({
  getAll(params?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/`, { params: { ...params } });
  },
  create(payload: Record<string, any>) {
    return axios.post(`${BASE_PATH}/campaign`, payload);
  },
  getAccounts(params?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/accounts`, { params: { ...params } });
  },
  getBrands(account_id: number | string) {
    return axios.get(`${BASE_PATH}/${account_id}/brands`);
  },
  getProductsFromBrands(brand_id: number | string) {
    return axios.get(`${BASE_PATH}/${brand_id}/products`);
  },
});