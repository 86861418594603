const BASE_PATH = "/accounts"
export default (axios: any) => ({
    getAll(params?: Record<string, any>) {
        return axios.get(`${BASE_PATH}/`, { params: { ...params } })
    },
    create(payload?: Record<string, any>) {
        return axios.post(`${BASE_PATH}/`, payload);
    },
    delete(account_id: number | string) {
        const params = account_id
        return axios.delete(`${BASE_PATH}/${params}`);
    },
    //  For Accounts Module use
    getClientAccountById(id?: Record<string, any>) {
        return axios.get(`${BASE_PATH}/${id}`)
    },
    getClientAccountsBySearch(params?: Record<string, any>) {
        return axios.get(`${BASE_PATH}/search?${params}`)
    },
    checkAccountPlatform(account_id: number | string, platform_id: number | string) {
        return axios.get(`${BASE_PATH}/${account_id}/${platform_id}`)
    },
    updateClientAccounts(account_id: number | string, payload?: Record<string, any>) {
        return axios.patch(`${BASE_PATH}/`, payload);
    },
    disconnectPlatform(account_id: number | string,) {
        return axios.post(`${BASE_PATH}/${account_id}/disconnect`);
    },
    connectAmz(account_id: number | string, payload?: Record<string, any>) {
        return axios.post(`${BASE_PATH}/${account_id}/connect-amz`, payload);
    },
    connectQoo10(account_id: number | string, payload?: Record<string, any>) {
        return axios.post(`${BASE_PATH}/${account_id}/connect-qoo10`, payload);
    },
})