const BASE_PATH = "/campaigns";
export default (axios: any) => ({
  getAll(params?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/management`, { params: { ...params } });
  },
  delete(influencer_id: number | string) {
    const params = { influencer_id };
    return axios.delete(`${BASE_PATH}/management/${influencer_id}`);
  },
  getCampaignById(campaign_id: number | string) {
    const params = { campaign_id };
    return axios.get(`${BASE_PATH}/management/${campaign_id}`);
  },
  searchInfluencer(params?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/search/influencers`, { params: { ...params } });
  },
  updateCampaign(payload: Record<string, any>) {
    return axios.patch(`${BASE_PATH}/management`, payload);
  },
  createCampaign(payload: Record<string, any>) {
    return axios.post(`${BASE_PATH}/management`, payload);
  },
  getCampaignOverviewTableData(params?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/overview/table`, { params: { ...params } });
  },
  getCampaignSpecificById(campaign_id: number | string) {
    const params = { campaign_id };
    return axios.get(`${BASE_PATH}/overview/${campaign_id}`);
  },
  getCampaignOverviewGraphData(params?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/overview/graph`, { params: { ...params } });
  },
  getCampaignOverviewStatsData(params?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/overview/summary`, { params: { ...params } });
  },
  createNewCampaign(payload: Record<string, any>) {
    return axios.post(`${BASE_PATH}/`, payload);
  },
  getAllCampaigns(params?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/`, { params: { ...params } });
  },
  deleteCampaign(campaign_id: number | string) {
    const params = { campaign_id };
    return axios.delete(`${BASE_PATH}/${campaign_id}`);
  },
  getCampaignOverviewByProducts(params?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/overview/products`, { params: { ...params } })
  },  
  getCampaignSpecificationById(campaign_id: number | string) {
    const params = { campaign_id };
    return axios.get(`${BASE_PATH}/manage/${campaign_id}`);
  },
  updateNewCampaign(campaign_id: number | string, payload: Record<string, any>) {
    return axios.patch(`${BASE_PATH}/${campaign_id}`, payload);
  },
  // createCampaignMember(campaign_id: number | string, influencer_id: number | string, payload: Record<string, any>) {
  //   return axios.post(`${BASE_PATH}/${campaign_id}/influencer/${influencer_id}`, payload);
  // },
  createCampaignMembers(campaign_id: number | string, payload: { influencers: number[] }) {
    return axios.post(`${BASE_PATH}/${campaign_id}/influencer`, payload);
  },
  deleteInfluencerFromCampaign(campaign_id : number | string, influencer_id: number | string) {
    const params = { campaign_id, influencer_id };
    return axios.delete(`${BASE_PATH}/${campaign_id}/influencer/${influencer_id}`);
  },
  deleteContentFromCampaign(content_id: number | string) {
    const params = { content_id };
    return axios.delete(`${BASE_PATH}/content/${content_id}`);
  },
  
  getCampaignSpecificationMemberById(campaign_id: number | string) {
    const params = { campaign_id };
    return axios.get(`${BASE_PATH}/${campaign_id}`);
  },
  getCampaignDataById(campaign_id: number | string) {
    const params = { campaign_id };
    return axios.get(`${BASE_PATH}/${campaign_id}`);
  },
  getCampaignSpecificationContentById(campaign_id: number | string) {
    const params = { campaign_id };
    return axios.get(`${BASE_PATH}/${campaign_id}/content`);
  },
  getCampaignOverviewByContent(params?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/overview/contents`, { params: { ...params } })
  }, 
  ImportContentToCampaign(campaign_id: number | string, payload: Record<string, any>) {
    return axios.post(`${BASE_PATH}/${campaign_id}/content`, payload);
  },
  AddProducts(campaign_id: number | string, payload: Record<string, any>) {
    return axios.post(`${BASE_PATH}/${campaign_id}/product`, payload);
  },
  deleteProductFromCampaign(campaign_id : number | string, product_id: number | string) {
    const params = { campaign_id, product_id };
    return axios.delete(`${BASE_PATH}/${campaign_id}/product/${product_id}`);
  },
  // getCampaignProductPerformance(params?: Record<string, any>) {
  //   return axios.get(`${BASE_PATH}/performance/products`);
  // },

  getCampaignProductPerformance(params?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/performance/products`, { params });
  },
  updateCampaignContent(content_id: number | string, payload: Record<string, any>) {
    return axios.patch(`${BASE_PATH}/content/${content_id}`, payload);
  },
  updateCampaignInfluencer(campaign_id: number | string, influencer_id: number | string, budget: number | string) {
    return axios.patch(`${BASE_PATH}/${campaign_id}/influencer/${influencer_id}?budget=${budget}`);
  }
});