const BASE_PATH = "/platforms";
export default (axios: any) => ({
  allPlatform(params?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/`, { params: { ...params } });
  },
  getCount() {
    return axios.get(`${BASE_PATH}/all_count`)
  },
  tiktokUsers(filters?: Record<string, any>, limit?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/tiktok?${filters}`, { params: { ...limit } });
  },
  InstagramUsers(filters?: Record<string, any>, limit?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/instagram?${filters}`, { params: { ...limit } });
  },
  YoutubeUsers(filters?: Record<string, any>, limit?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/youtube?${filters}`, { params: { ...limit } });
  },
  TwitterUsers(filters?: Record<string, any>, limit?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/twitter?${filters}`, { params: { ...limit } });
  },
  searchInfluencer(platform: string, params?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/${platform}/search`, { params: { ...params } });
  },
});