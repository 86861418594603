import type { dataTypeForLocalStorage } from "../generalStorage";

const getUser = () => {
    const storedUser = localStorage.getItem('user');
    if (storedUser !== null && storedUser !== undefined) {
        try {
            return JSON.parse(storedUser);
        } catch (err) {
            return storedUser;
        }
    }
    return null;
};
const getUserToken = () => {
    const storedToken = localStorage.getItem('token');
    if (storedToken !== null && storedToken !== undefined) {
        try {
            const user = JSON.parse(storedToken)
            return user['access_token'];
        } catch (err) {
            return storedToken;
        }
    }
    return null;
};

const getTestToken = () => {
    const storedToken = localStorage.getItem('user');
    if (storedToken !== null && storedToken !== undefined) {
        try {
            const user = JSON.parse(storedToken)
            return user['access_token'];
        } catch (err) {
            return storedToken;
        }
    }
    return null;
};
const getUserTokenExpire = () => {
    const storedToken = localStorage.getItem('token');
    if (storedToken !== null && storedToken !== undefined) {
        try {
            const user = JSON.parse(storedToken)
            return user['expire'];
        } catch (err) {
            return storedToken;
        }
    }
    return null;
};
const setUser = (data: dataTypeForLocalStorage) => {
    if (data !== null || data !== undefined) {
        localStorage.setItem('user', JSON.stringify(data));
    }
};
const setToken = (data: dataTypeForLocalStorage) => {
    if (data !== null || data !== undefined) {
        localStorage.setItem('token', JSON.stringify(data));
    }
};
const deleteUser = () => {
    localStorage.removeItem('user')
}
const deleteToken = () => {
    localStorage.removeItem('token')
}
export const UserStorage = {
    getUser,
    getUserToken,
    setUser,
    deleteUser,
    setToken,
    deleteToken,
    getUserTokenExpire,
    getTestToken
}