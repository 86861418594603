import { type TeamUsers } from '../../types/User';
const BASE_PATH = '/users';
export default (axios: any) => ({
  getAll(params?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/`, { params: { ...params } });
  },
  me(params?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/me`, { params: { ...params } });
  },
  update(params?: Record<string, any>) {
    return axios.patch(`${BASE_PATH}/access`, null, { params: { ...params } });
  },
  updateStatus(user_id: number | string, payload: Record<string, any>) {
    return axios.patch(`${BASE_PATH}/${user_id}`, payload);
  }
});
