const BASE_PATH = "/vh-menus"
export default (axios: any) => ({
    getActive(params?: Record<string, any>) {
        return axios.get(`${BASE_PATH}/bar`, { params: { ...params } })
    },
    getAll(params?: Record<string, any>) {
        return axios.get(`${BASE_PATH}/`, { params: { ...params } })
    },
    create(payload?: Record<string, any>) {
        return axios.post(`${BASE_PATH}/`, payload);
    },
    update(payload?: Record<string, any>) {
        return axios.patch(`${BASE_PATH}/`, payload);
    },
    delete(menu_id: number | string) {
        return axios.delete(`${BASE_PATH}/${menu_id}`);
    },
})