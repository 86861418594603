import axios from 'axios';
import { UserStorage } from '~/Storages/userStorage';
import { userParseError } from '~/composables/parseError';
import { routes } from '~/enum/route.enum';
import influencermanagement from '~/repository/modules/influencermanagement';
import campaignmanagement from '~/repository/modules/campaignmanagement';
import compaignlogs from '~/repository/modules/compaignlogs';
import influencerscampaigns from '~/repository/modules/influencerscampaigns';
import payments from '~/repository/modules/payments';
import platform from '~/repository/modules/platform';
import leadgroup from '~/repository/modules/leadgroup';
import reachout from '~/repository/modules/reachout';
import emailtemplate from '~/repository/modules/emailtemplate';
import products from '~/repository/modules/products';
import discovery from '~/repository/modules/discovery';
import menus from '~/repository/modules/menus';
import productaccounts from '~/repository/modules/productaccounts';
import accounts from '~/repository/modules/accounts';
import brands from '~/repository/modules/brands';
import community from '~/repository/modules/community';

import users from '~/repository/modules/users';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  axios.defaults.baseURL = config.public.baseURL;
  axios.defaults.headers = {
    common: {
      accept:
        'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*',
      'accept-language': 'en-US,en;q=0.9',
      'cache-control': 'no-cache',
      'content-type': 'application/json'
    }
  };
  axios.interceptors.request.use(async (config) => {
    config.headers.Authorization = `Bearer ${UserStorage.getUserToken()}`;
    // config.headers.Authorization = `Bearer ${UserStorage.getTestToken()}`;
    return config;
  });
  axios.interceptors.response.use(
    (res) => {
      if (!res?.status) throw new Error('Invalid response');
      else return res;
    },
    (err) => {
      if (err && err.response) {
        switch (err.response.status) {
          case 401:
            nuxtApp?.$router?.push(routes.LOGIN);
            break;
          default:
            const error = { status: err.response.status, ...err.response.data };
            nuxtApp?.$toast?.error(userParseError(error), {
              timeout: 3000
            });
            return new Promise((res, rej) => rej(error));
        }
      } else {
        return new Promise((res, rej) => rej('Unknown error'));
      }
    }
  );
  const modules = {
    // accessSettings: accessSettings(axios),
    // auth: auth(axios),
    users: users(axios),
    influencermanagement: influencermanagement(axios),
    campaignmanagement: campaignmanagement(axios),
    compaignlogs: compaignlogs(axios),
    influencerscampaigns: influencerscampaigns(axios),
    payments: payments(axios),
    platform: platform(axios),
    leadgroup: leadgroup(axios),
    reachout: reachout(axios),
    emailtemplate: emailtemplate(axios),
    products: products(axios),
    discovery: discovery(axios),
    menus: menus(axios),
    productaccounts: productaccounts(axios),
    accounts: accounts(axios),
    brands: brands(axios),
    community: community(axios)
  };
  return {
    provide: {
      API: modules
    }
  };
});
