const BASE_PATH = '/influencers';
export default (axios: any) => ({
  getRows(params?: Record<string, any>) {
    return axios.post(`${BASE_PATH}/grid`, params);
  },
  getAll(params?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/`, { params: { ...params } });
  },
  getCount() {
    return axios.get(`${BASE_PATH}/count`);
  },
  createInfluencer(payload: Record<string, any>) {
    return axios.post(`${BASE_PATH}/`, payload);
  },
  delete(influencer_id: number | string) {
    const params = { influencer_id };
    return axios.delete(`${BASE_PATH}/${influencer_id}`);
  },
  getInfluencerById(influencer_id: number | string) {
    const params = { influencer_id };
    return axios.get(`${BASE_PATH}/${influencer_id}`);
  },
  updateInfluencer(payload: Record<string, any>) {
    return axios.patch(`${BASE_PATH}/`, payload);
  },
  updateInfluencerApplicationStatus(payload: Record<string, any>) {
    return axios.patch(`${BASE_PATH}/application`, payload);
  },
  getInfluencerByPlatform(platform: number | string, limit?: Record<string, any>) {
    const params = { platform };
    return axios.get(`${BASE_PATH}/${platform}/all`, { params: { ...limit } });
  },
  searchInfluencer(params?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/search`, { params: { ...params } });
  }
});
