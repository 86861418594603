const BASE_PATH = "/lead_groups";
export default (axios: any) => ({
  getAll(params?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/`, { params: { ...params } });
  },
  createLeadGroup(payload: Record<string, any>) {
    return axios.post(`${BASE_PATH}/`, payload);
  },
  deleteLeadGroup(grouplead_id: number | string) {
    const params = { grouplead_id };
    return axios.delete(`${BASE_PATH}/${grouplead_id}`);
  },
  getLeadGroupById(grouplead_id: number | string) {
    const params = { grouplead_id };
    return axios.get(`${BASE_PATH}/${grouplead_id}`);
  },
  leadGroupMembersById(grouplead_id: number | string) {
    const params = { grouplead_id };
    return axios.get(`${BASE_PATH}/${grouplead_id}/members`);
  },
  updateLeadGroup(payload: Record<string, any>) {
    return axios.patch(`${BASE_PATH}/`, payload);
  },
  createLeadGroupMember(payload: Record<string, any>) {
    return axios.post(`${BASE_PATH}/members`, payload);
  },
  deleteInfluencerFromLeadGroup(group_id : number | string, influencer_id: number | string) {
    const params = { group_id, influencer_id };
    return axios.delete(`${BASE_PATH}/${group_id}/members/${influencer_id}`);
  },
});