const BASE_PATH = "/reach_out";
export default (axios: any) => ({
  getAll(params?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/`, { params: { ...params } });
  },
  createReachOut(payload: Record<string, any>) {
    return axios.post(`${BASE_PATH}/`, payload);
  },
  deleteReachOut(reachout_id: number | string) {
    const params = { reachout_id };
    return axios.delete(`${BASE_PATH}/${reachout_id}`);
  },
  getReachOutById(reachout_id: number | string) {
    const params = { reachout_id };
    return axios.get(`${BASE_PATH}/${reachout_id}`);
  },
  updateReachOut(payload: Record<string, any>) {
    return axios.patch(`${BASE_PATH}/`, payload);
  },
  reachOutMembersById(reachout_id: number | string) {
    const params = { reachout_id };
    return axios.get(`${BASE_PATH}/${reachout_id}/members`);
  },
  createReachoutMember(payload: Record<string, any>) {
    return axios.post(`${BASE_PATH}/members`, payload);
  },
  deleteInfluencerFromReachout(group_id : number | string, influencer_id: number | string) {
    const params = { group_id, influencer_id };
    return axios.delete(`${BASE_PATH}/${group_id}/members/${influencer_id}`);
  },
});