const BASE_PATH = '/vh-community-content';
export default (axios: any) => ({
  getAll(params?: Record<string, any>) {
    return axios.get(`${BASE_PATH}/`, { params: { ...params } });
  },
  updateContent(payload: Record<string, any>) {
    return axios.patch(`${BASE_PATH}/`, payload);
  },
  createContent(payload: Record<string, any>) {
    return axios.post(`${BASE_PATH}/`, payload);
  }
});
