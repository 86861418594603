export const routes=Object.freeze({
    INDEX: "/",
    HOME: "/home",
    LOGIN:"/login",
    INFLUENCERMANAGEMENT: "/influencer",
    COMPAIGNMANAGEMENT: "/CompaignManagement",
    CAMPAIGNOVERVIEW: "/CampaignOverview?metric=views",
    PAYMENTS: "/Payments",
    LEADGROUP: "/lead-group",
    OVERVIEW: "/overview",
    REACHOUT: "/reach-out",
    CAMPAIGNS: "/campaigns",
    DISCOVERY: "/discovery",
    USERSETTING: "/settings/user",
    MENUS: "/settings/menu",
    PRODUCTS: "/products",
})
