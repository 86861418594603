const BASE_PATH = "/campaign_log";
export default (axios: any) => ({
  getLogs(influencer_id: number | string) {
    const params = { influencer_id };
    return axios.get(`${BASE_PATH}/${influencer_id}`);
  },
  deleteLogs(log_id: number | string) {
    const params = { log_id };
    return axios.delete(`${BASE_PATH}/${log_id}`);
  },
  updateActivityLog(payload: Record<string, any>) {
    return axios.patch(`${BASE_PATH}/`, payload);
  },
  createLogs(payload: Record<string, any>) {
    return axios.post(`${BASE_PATH}/`, payload);
  },
  campaignSpecificLogs(campaign_id: number | string, influencer_id: number | string) {
    const params = { influencer_id };
    return axios.get(`${BASE_PATH}/${campaign_id}/${influencer_id}`);
  },
});